<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('package_info.package_info') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link to="/points-credits/package-info" class="btn btn-light font-weight-bolder">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('back') }}
          </router-link>
          <!--end::Button-->
        </div>
      </div>

      <div class="card-body">
        <b-card-body class="p-0">
          <div class="form-group row">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('package_info.name') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('package_info.price') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.price" class="form-control" :class="validation && validation.price ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.price" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.price[0] }}
                            </span>
            </div>

            <div class="col-lg-3 mb-5">
              <label>{{ $t('package_info.period_val') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.period_val" class="form-control" :class="validation && validation.period_val ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.period_val" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.period_val[0] }}
                            </span>
            </div>

            <div class="col-lg-3 mb-5">
              <label>{{ $t('package_info.period_type') }}<span class="text-danger">*</span></label>
              <select name="" id="period_type" v-model="data.period_type" type="text" class="custom-select" :class="validation && validation.period_type ? 'is-invalid' : ''">
                <option v-for="(row, index) in period_type" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.period_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.period_type[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('package_info.description') }}</label>
              <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
              <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
            </div>

          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <hr>
            </div>

            <div class="col-md-12">
              <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803">
                <h6 class="my-auto text-white">{{ $t('package_info.package_details') }}</h6>
                <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{ $t('add_more') }}</button>
              </div>
            </div>
            <div class="col-md-12">
              <table class="table table-row-bordered" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList(0)">
                <thead>
                <tr>
                  <th width="30%">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('package_info.credit_type') }}</span>
                      <a href="/points-credits/credit-types/create" target="_blank" class="btn btn-primary btn-sm p-1">
                        <i class="fa fa-plus add-new"></i>
                      </a>
                    </div>
                  </th>
                  <th width="30%">{{ $t('package_info.credit_amount') }}</th>
                  <th></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in package_details" :key="index">
                  <td>
                    <multiselect v-model="row.credit_type"
                                 :placeholder="$t('package_info.credit_type')"
                                 label="name"
                                 track-by="id"
                                 :options="credit_type"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                  </td>
                  <td><input v-model="row.credit_amount" type="number" class="form-control"></td>
                  <td>
                    <v-icon style="color: #dc3545;" color="danger" small v-if="package_details.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>

              </table>
            </div>
          </div>
        </b-card-body>

        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-package-info",
  data() {
    return {
      mainRoute: 'pointscredits/packages-info',
      mainRouteDependency: 'base/dependency',


      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        price: '',
        period_val: '',
        period_type: '',
        status: true,
        description: null,
      },
      isEditing: false,
      validation: null,
      period_type: [],
      credit_type: [],
      package_details_form: {id: null, credit_type: null, credit_amount: null},
      package_details: [],

    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        package_details: this.package_details,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/points-credits/package-info');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        package_details: this.package_details,
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/points-credits/package-info');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getPeriodType() {
      ApiService.get(this.mainRouteDependency + "/period_type").then((response) => {
        this.period_type = response.data.data;
      });
    },

    getCreditTypes() {
      ApiService.get(this.mainRouteDependency + "/credit_types").then((response) => {
        this.credit_type = response.data.data;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.period_val = response.data.data.period_val;
        this.data.period_type = response.data.data.period_type;
        this.data.price = response.data.data.price;
        this.data.description = response.data.data.description;
        this.data.status = response.data.data.status;

        this.package_details = response.data.data.package_details;
        // this.package_details.credit_type = response.data.data.package_details.credit_type;

        if (response.data.data.package_details && response.data.data.package_details.length <= 0) {
          this.addItemRowToList();
        }
      });
    },

    addItemRowToList() {
      this.package_details.unshift(this.package_details_form);
      this.package_details_form = {id: null, credit_type: null, credit_amount: null};
    },
    removeItemRowFromList(index) {
      if (this.package_details.length > 1) {
        this.package_details.splice(index, 1);
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.package_info")}]);
    this.getPeriodType();
    this.getCreditTypes();

    if (this.idEdit) {
      this.getData();
    } else {
      if (this.package_details.length <= 0) {
        this.addItemRowToList();
      }
    }

  },
};
</script>


